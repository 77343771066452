/*eslint-disable */
// import CareerCourseModel from '../../Model/CareerCourse'
import request from '../../Utils/curl'

let CareerCourses = {
  /**
   * careerCourseList
   */
  async careerCourseList (context) {
    try{
      let post_data = new FormData();
  
      return await request.curl(context, "careerCourse_list", post_data)
        .then(async response => {
          return response
        });

    }
    catch(err){
      console.error('Exception occurred at careerCourseList() and Exception:',err.message)
    }
  },

  /**
   * careerCourseView
   */
  async careerCourseView (context, careerCourseId) {
    try {     
      let post_data = new FormData();
      post_data.append('carr_course_id', careerCourseId);
      return await request.curl(context, "careerCourse_view", post_data)
      .then(async response => {
        return response;
      });
    }
    catch (err) {
      console.error("Exception occurred at careerCourseView() and Exception:",err.message)
    }
    
  },

  /**
   * careerCourseAdd
   */
  async careerCourseAdd (context, careerCourseObj) {
    try{
    let post_data = new FormData();
    
    for (let key in careerCourseObj) {
      post_data.append(key, careerCourseObj[key]);
    }
    return await request.curl(context, "careerCourse_add", post_data)
      .then(async response => {
        return response;
      });
    }
    catch(err){
      console.error('Exception occurred at careerCourseAdd() and Exception:',err.message)
    }
  },

  /**
   * careerCourseEdit
   */
  async careerCourseEdit (context, careerCourseObj) {
    try{
    let post_data = new FormData();
    
    for (let key in careerCourseObj) {
      post_data.append(key, careerCourseObj[key]);
    }

    return await request.curl(context, "careerCourse_edit", post_data)
      .then(async response => {
        return response;
      });
    }
    catch(err){
      console.error('Exception occurred at careerCourseEdit() and Exception:',err.message)
    }
  },

  /**
   * careerCourseDelete
   */
  async careerCourseDelete (context, careerCourseId) {
    try{
    let post_data = new FormData();
    
    post_data.append('carr_course_id', careerCourseId);

    return await request.curl(context, "careerCourse_delete", post_data)
      .then(async response => {
        return response;
      });
    }
    catch(err){
      console.log('Exception occurred at careerCourseDelete() and Exception:',err.message)
    }
  }
}

export {
  CareerCourses
}
